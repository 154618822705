import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1>Hi, I'm Kalle Lamminpää</h1>
    <p>
      I'm a dad, dev and ops with 8+ years of experience creating the best web
      services in Finland.
    </p>
    <p>My current stack includes typescript, graphql, kubernetes and fluxcd. I  love to create services on Google Cloud Platform but I feel like the cloud provider should not matter that much when creating cloud native applications.</p>
    <p>
      Contact{" "}
      <a href="https://twitter.com/kallelam" aria-label="twitter">
        Twitter
      </a>
      {", "}
      <a href="https://www.linkedin.com/in/lamminpaa/" aria-label="linkedin">
        LinkedIn
      </a>
    </p>
  </Layout>
);

export default IndexPage;
